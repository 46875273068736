@font-face {
  font-family:'Futura Std';
  src: local('Futura Std'), url(./Static/fonts/FuturaStdMedium.woff) format('woff');
  font-weight:500;
  font-style:normal
}

@font-face {
  font-family:'Futura Std';
  src: local('Futura Std'), url(./Static/fonts/FuturaStdHeavy.woff) format('woff');
  font-weight: 900;
  font-style:normal
}

@font-face {
  font-family:'Futura Std';
  src: local('Futura Std'), url(./Static/fonts/FuturaStdBold.woff) format('woff');
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family:'Futura Std';
  src: local('Futura Std'), url(./Static/fonts/FuturaStdBook.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: local('Futura Std'), url(./Static/fonts/fa-solid-900.woff) format('woff');
}

.App {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: 'Futura Std';
  min-height: 100vh;
  flex-flow: column nowrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker-wrapper {
  width: 100%;
}

input[type="text"] {
  width: calc(100% - 24px);
  min-width: calc(100% - 24px);
  background: #f7f7f7;
  border: 1px solid #c4c4c4;
  height: 26px;
  color: #787878;
  border-radius: 0;
  box-shadow: none;
  transition: all .5s;
  font-size: 18px;
  padding: 12px;
}

.loading {
  padding-top: 60px;
  font-size: 18px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



